<template>
    <!--    큐레이션 의뢰할 아이템 리스트 레이아웃<br>
        <button @click="moveDetail(`${idx}`)">외뢰할 아이템으로 가기</button>-->
    <div class="item_list_curation_wrap">
        <ul class="entry_list_wrap">
            <li class="list_item" v-for="(item, index) in itemData" :key="`item${item.i_idx}`" @click="openMoveCurationRequestConfirm(item)">
                <div class="thumbnail">
                    <img :src="returnThumbnail(item)" alt="">
                    <div class="card_category cc_category">{{ item.ic_name_eng }}</div>
                </div>
                <div class="card_detail">
                    <div class="card_title">{{ item.i_name }}</div>
                </div>
            </li>

        </ul>
    </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import imageOption from "@/mixins/imageOption";
import util from "@/mixins/util";
import imageResizeMixins from "@/mixins/imageResizeMixins";

export default {
    name: "ItemListCurationRequestLayout",
    mixins: [imageOption, alertMixins, imageResizeMixins],
    components: {

    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
        itemData: {default: () => []},
    },
    data() {
        return{
            memberKey: Number(this.$route.params.mb_no),
        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        moveDetail(item) {
            this.$router.push(`/curation/${this.memberKey}/request/${item.i_idx}`);
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.ItemFile) && !util.isEmpty(data.ItemFile.Item)) {
                // return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(278, null, null)}`;
                return this.setFitWidthImg(data.ItemFile.Item[0], 278);
            }
        },
        openMoveCurationRequestConfirm(item) {
            this.createConfirm({
                content: `'<span class="subject">${item.i_name}</span>'<br>${this.$t('curation_item_confirm')}`,
                confirm: () => {
                    this.moveDetail(item);
                }
            })
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
